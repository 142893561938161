import '@fontsource/montserrat'
import 'react-toastify/dist/ReactToastify.css'
import './src/styles/global.css'
import { Provider } from 'react-redux'
import { Auth0Provider } from '@auth0/auth0-react'
import { setupStore } from './src/store'
import { navigate } from 'gatsby'

const store = setupStore()

const onRedirectCallback = appState => {
  // Use Gatsby's navigate method to replace the url
  navigate(appState?.returnTo || '/', { replace: true })
}

export const wrapRootElement = ({ element }) => {
  return (
    <Auth0Provider
      domain="prod-tritag.au.auth0.com"
      clientId="vpGlRw3fT2ZOqsigailMpT6bfeU8cnwF"
      audience="https://prod-tritag.au.auth0.com/api/v2/"
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <Provider store={store}>{element}</Provider>
    </Auth0Provider>
  )
}
